import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';  // Za dohvaćanje parametra iz URL-a
import { fetchSpecializedDictionary } from '../services/api';  // API za dohvaćanje podataka
import { LanguageContext } from '../context/LanguageContext';  // Kontekst za jezike
import './SpecializedDictionary.css';  // Uvezi CSS za stilizaciju
import DictionaryModal from './DictionaryModal';  // Komponenta za modal
import { speakText } from '../utils/speechUtils';  // Importiraj funkciju za govor

const SpecializedDictionary = () => {
  const { jobId } = useParams();  // Dohvati jobId iz URL-a
  const { euLanguage, nonEuLanguage, croatianLanguage = 'hr' } = useContext(LanguageContext);  // Jezici iz konteksta
  const [dictionary, setDictionary] = useState([]);
  const [jobName, setJobName] = useState('');  // Dodano za prikaz naziva posla
  const [selectedWord, setSelectedWord] = useState(null);  // Za pohranu odabrane riječi za modal
  const [showModal, setShowModal] = useState(false);  // Stanje za otvaranje/zatvaranje modala

  // Dohvati specijalizirani rječnik za odabrano zanimanje
  useEffect(() => {
    fetchSpecializedDictionary(jobId)  // Pozovi API za dohvaćanje rječnika
      .then(response => {
        setDictionary(response.data);  // Postavi dohvaćene riječi iz rječnika
        
        // Postavi naziv posla ako postoji
        if (response.data.length > 0) {
          setJobName(response.data[0].job_name);  // Pretpostavka da API vraća naziv posla
        }
      })
      .catch(error => console.error('Error fetching specialized dictionary:', error));
  }, [jobId]);

  // Funkcija za otvaranje modala s detaljima riječi
  const openModal = (word) => {
    setSelectedWord(word);  // Postavi odabranu riječ
    setShowModal(true);  // Otvori modal
  };

  // Funkcija za zatvaranje modala
  const closeModal = () => {
    setShowModal(false);  // Zatvori modal
    setSelectedWord(null);  // Očisti odabranu riječ
  };

  return (
    <div className="specialized-dictionary-container">
      <h2>Specialized Dictionary for {jobName}</h2> {/* Prikaz naziva zanimanja */}

      <div className="dictionary-grid">
        {dictionary.map((word, index) => (
          <div key={index} className="word-card" onClick={() => openModal(word)}>
            <img
              src={word.image_url || '/images/placeholder.png'}  // Fallback slika ako nedostaje
              alt={word[`word_${croatianLanguage}`]}
              className="word-image"
            />
            <h3 onClick={() => speakText(word[`word_${croatianLanguage}`], 'hr')}>
              {word[`word_${croatianLanguage}`]}
            </h3>

            <p onClick={() => speakText(word[`word_${euLanguage}`], euLanguage)}>
              {word[`word_${euLanguage}`] || 'N/A'}
            </p>

            <p onClick={() => speakText(word[`word_${nonEuLanguage}`], nonEuLanguage)}>
            {word[`word_${nonEuLanguage}`] || 'N/A'}</p> {/* Izgovor za non-EU jezike nije potreban */}

          </div>
        ))}
      </div>

     {/* Modal za prikaz detalja riječi */}
      {showModal && selectedWord && (
        <DictionaryModal
          word={selectedWord}
          closeModal={closeModal}
          croatianLanguage={croatianLanguage}
          euLanguage={euLanguage}
          nonEuLanguage={nonEuLanguage}
        >
          {/* Izgovor unutar modalne komponente */}
          <div className="modal-content">
            <h3 onClick={() => speakText(selectedWord[`word_${croatianLanguage}`], 'hr')}>
              {selectedWord[`word_${croatianLanguage}`]}
            </h3>
            <p onClick={() => speakText(selectedWord[`word_${euLanguage}`], euLanguage)}>
              {selectedWord[`word_${euLanguage}`] || 'N/A'}
            </p>

            <p onClick={() => speakText(selectedWord[`word_${nonEuLanguage}`], nonEuLanguage)}>
            {selectedWord[`word_${nonEuLanguage}`] || 'N/A'}</p> 
          </div>
        </DictionaryModal>
      )}
    </div>
  );
};

export default SpecializedDictionary;
